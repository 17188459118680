import React from "react"
import Layout from "../components/Layout"

const Impressum = () => {
  return (
    <Layout
      title="Impressum"
      secondTitle="Die Stadt zum Einkaufen und Wohlfühlen"
      seoTitle="WirtschaftsPlattForm Schwechat | Impressum"
    >
      <section className="section has-background-white-er">
        <div className="container">
          <h3 className="title is-3">Impressum</h3>
          <div className="content">
            <h5 className="title is-5">WirtschaftsPlattForm Schwechat</h5>
            <p className="is-size-5">Schmidgasse 6</p>
            <p className="is-size-5">
              E-Mail:{" "}
              <a href="mailto:info@WirtschaftsPlattForm.at">
                info@WirtschaftsPlattForm.at
              </a>
            </p>
          </div>
          <div className="content">
            <h4 className="title is-4">Unternehmensgegenstand</h4>
            <p className="is-size-5">
              Die WirtschaftsPlattForm Schwechat hat zum Zweck, den Standort
              Schwechat als Einkaufsstadt für den Konsumenten attraktiver zu
              gestalten und dadurch die in Schwechat wirksame Kaufkraft zu
              erhöhen.
            </p>
          </div>
          <div className="content">
            <h4 className="title is-4">Offizieller Vertreter nach außen:</h4>
            <p className="is-size-5">Obfrau Elisabeth Strini</p>
            {/* <p className="is-size-5">Stellvertreter </p> */}
          </div>
          <h4 className="title is-4">Bildquellen</h4>
          <p className="is-size-5">&copy; Bwag</p>
        </div>
      </section>
    </Layout>
  )
}

export default Impressum
